<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="500"
    :loading="loading"
    @close="onClose"
    @ok="onSubmit"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <a-row>
          <a-col :span="12">
            <e-input-number
              title="最小人数"
              v-model:value="modelRef.min"
              :min="1"
            />
          </a-col>
          <a-col :span="12">
            <e-input-number
              title="最大人数"
              v-model:value="modelRef.max"
              :min="1"
            />
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { Form } from 'ant-design-vue'
import { ComboClass } from '@/apis/combo'
import form from '@/mixins/form'

const useForm = Form.useForm
const api = new ComboClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)

    const combo = ref()
    const title = ref('')

    const modelRef = reactive({
      comboId: '',
      min: 1,
      max: 1
    })
    const rulesRef = reactive({})

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()
      loading.value = false
      visible.value = false
    }

    const onSubmit = () => {
      loading.value = true
      validate().then(() => {
        api.copy(toRaw(modelRef)).then(() => {
          onClose()
          emit('ok')
        })
      })
    }

    return {
      visible,
      loading,
      combo,
      title,
      modelRef,
      validateInfos,
      resetFields,
      validate,
      onClose,
      onSubmit
    }
  },

  methods: {
    open (combo) {
      this.combo = combo
      this.title = `复制套餐：${combo.name}`
      this.modelRef.comboId = combo.id
      this.visible = true
    }
  }
})
</script>

<style lang="less" scoped></style>
